import React, { Fragment, useEffect, useState } from 'react'
import Component from '../../components/common/Component'
import Grid from '@mui/material/Grid'
import {
  addStockTemplate,
  getStockTemplates,
  getStockTemplateTemplates,
  removeStockTemplate,
} from '../../api/product'
import { err, formatDate, wait } from '../../utils/functions'
import { DataFields, ProductTemplate, StockTemplate } from '../../api/types'
import JoyDataTable from '../../components/common/JoyDataTable'
import Typography from '@mui/joy/Typography'
import Chip from '@mui/joy/Chip'
import Avatar from '@mui/joy/Avatar'
import AvatarGroup from '@mui/joy/AvatarGroup'
import Button from '@mui/joy/Button'
import Checkbox from '@mui/joy/Checkbox'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Tooltip from '@mui/joy/Tooltip'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import FloatLabelInput from '../../components/common/FloatLabelInput'
import MultipleSelect from '../../components/common/MultipleSelect'
import FloatLabelTextarea from '../../components/common/FloatLabelTextarea'
import InputContainer from '../../components/common/InputContainer'
import StorefrontIcon from '@mui/icons-material/Storefront'
import ChecklistIcon from '@mui/icons-material/Checklist'

export default function TemplateLibraryDashboard(): JSX.Element {
  const [alert, setAlert] = useState<AlertInput>({ open: false })
  const [addStockTemplateModal, setAddStockTemplateModal] =
    useState<boolean>(false)

  const [selectedTemplate, setSelectedTemplate] = useState<
    ProductTemplate | undefined
  >()

  const [stockTemplateName, setStockTemplateName] = useState<
    string | undefined
  >()
  const [stockTemplateDescription, setStockTemplateDescription] = useState<
    string | undefined
  >()

  const [stockTemplateCategory, setStockTemplateCategory] = useState<
    string | undefined
  >()

  const [stockTemplateSubCategory, setStockTemplateSubCategory] = useState<
    string | undefined
  >()
  const [stockTemplateTags, setStockTemplateTags] = useState<string[]>([])
  const [stockTemplateValueMapping, setStockTemplateValueMapping] =
    useState<boolean>(false)
  const [stockTemplateAllChannels, setStockTemplateAllChannels] =
    useState<boolean>(false)

  const [stockTemplates, setStockTemplates] = useState<
    StockTemplate[] | undefined
  >()

  const [stockTemplateTemplates, setStockTemplateTemplates] = useState<
    (ProductTemplate & { clientName: string })[] | undefined
  >()

  const handleRemoveStockTemplate = (stockTemplateId: number) => {
    removeStockTemplate(stockTemplateId)
      .then((res) => {
        handleAlert(
          setAlert,
          res,
          res.message || 'Removed template from library',
        )
      })
      .catch((e) => err(e))
  }

  const handleAddStockTemplate = () => {
    const template = selectedTemplate
    if (!template || !stockTemplateCategory) return
    const stockTemplate: DataFields<StockTemplate> = {
      name: stockTemplateName || template.name,
      description: stockTemplateDescription,
      category: stockTemplateCategory,
      subCategory: stockTemplateSubCategory,
      tags: stockTemplateTags.length ? stockTemplateTags.join(',') : undefined,
      valueMapping: stockTemplateValueMapping,
      allChannels: stockTemplateAllChannels,
      templateId: template.id,
    }

    addStockTemplate(stockTemplate)
      .then(async (res) => {
        handleAlert(setAlert, res, res.message || 'Added template to library')
        await wait(2000)
        setAddStockTemplateModal(false)
      })
      .catch((e) => err(e))
  }

  useEffect(() => {
    // get stock templates
    getStockTemplateTemplates()
      .then((res) => {
        if (res.success && res.data) {
          setStockTemplateTemplates(res.data)
          //
        }
      })
      .catch((e) => {
        err(e)
        handleAlert(setAlert, e.response.data, 'Error')
      })

    // get stock templates
    getStockTemplates()
      .then((res) => {
        if (res.success && res.data) {
          setStockTemplates(res.data)
          //
        }
      })
      .catch((e) => {
        err(e)
        handleAlert(setAlert, e.response.data, 'Error')
      })
  }, [])

  return (
    <Component>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">
                Available Stock Templates
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputContainer>
                    <JoyDataTable
                      data={stockTemplateTemplates || []}
                      // onSelection={(selectedRows) =>
                      //   setSelectedOrders?.(selectedRows)
                      // }
                      renderRow={(row) => (
                        <Fragment key={row.id}>
                          <td>{row.name}</td>
                          <td>
                            <Typography level="body-sm">
                              {row.clientName}
                            </Typography>
                          </td>
                          <td>
                            <Typography level="title-sm">
                              {formatDate(row.createdAt)}
                            </Typography>
                          </td>
                          <td>
                            {row.isStock ? (
                              <Button
                                size="sm"
                                color="neutral"
                                variant="plain"
                                onClick={() => {
                                  setSelectedTemplate(row)
                                  setStockTemplateName(row.name)
                                  setStockTemplateDescription(
                                    row.typeDescription,
                                  )
                                  setAddStockTemplateModal(true)
                                }}
                              >
                                Add to Library
                              </Button>
                            ) : (
                              <Typography level="body-sm">
                                Template is not stock
                              </Typography>
                            )}
                          </td>
                        </Fragment>
                      )}
                      columns={[
                        {
                          key: 'name',
                          label: 'Name',
                          sortable: true,
                          width: '150px',
                        },
                        {
                          key: 'clientName',
                          label: 'Client',
                          width: '80px',
                        },
                        {
                          key: 'createdAt',
                          label: 'Created',
                          sortable: true,
                          width: '80px',
                        },
                        {
                          key: 'isStock',
                          label: 'Action',
                          width: '80px',
                        },
                      ]}
                      selectable={false}
                    />
                  </InputContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="title-lg">Template Library</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputContainer>
                    <JoyDataTable
                      data={stockTemplates || []}
                      // onSelection={(selectedRows) =>
                      //   setSelectedOrders?.(selectedRows)
                      // }
                      renderRow={(row) => (
                        <Fragment key={row.id}>
                          <td>{row.name}</td>
                          <td>
                            <Typography level="body-sm">
                              {row.category}
                            </Typography>
                            <Typography level="body-xs">
                              {row.subCategory}
                            </Typography>
                          </td>
                          <td>
                            {row.tags
                              ? row.tags.split(',').map((tag, i) => {
                                  return (
                                    <Chip
                                      key={tag + i}
                                      sx={{
                                        ml: 0.33,
                                      }}
                                      size="sm"
                                      color="neutral"
                                    >
                                      {tag}
                                    </Chip>
                                  )
                                })
                              : ''}
                          </td>
                          <td>
                            <AvatarGroup>
                              {row.channels?.map((channel) => {
                                const iconSrc = `/channel_icons/${channel}.png`
                                return (
                                  <Avatar
                                    style={{
                                      border: '1px solid #eee',
                                    }}
                                    sx={{
                                      width: 24,
                                      height: 24,
                                    }}
                                    key={channel}
                                    alt={channel}
                                    src={iconSrc}
                                  />
                                )
                              }) || null}
                            </AvatarGroup>
                          </td>
                          <td>
                            {row.allChannels ? (
                              <Tooltip
                                title="Works with all channels"
                                size="sm"
                              >
                                <Chip
                                  sx={{
                                    ml: 0.33,
                                  }}
                                  size="sm"
                                  color="success"
                                  startDecorator={
                                    <StorefrontIcon fontSize="small" />
                                  }
                                ></Chip>
                              </Tooltip>
                            ) : null}
                            {row.valueMapping ? (
                              <Tooltip title="Features value mapping" size="sm">
                                <Chip
                                  sx={{
                                    ml: 0.33,
                                  }}
                                  size="sm"
                                  color="success"
                                  startDecorator={
                                    <ChecklistIcon fontSize="small" />
                                  }
                                ></Chip>
                              </Tooltip>
                            ) : null}
                          </td>
                          <td>
                            <Typography level="title-sm">
                              {formatDate(row.createdAt)}
                            </Typography>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="neutral"
                              variant="plain"
                              onClick={() => handleRemoveStockTemplate(row.id)}
                            >
                              Remove
                            </Button>
                          </td>
                        </Fragment>
                      )}
                      columns={[
                        {
                          key: 'name',
                          label: 'Name',
                          sortable: true,
                          width: '150px',
                        },
                        {
                          key: 'category',
                          label: 'Category',
                          sortable: true,
                          width: '80px',
                        },
                        {
                          key: 'tags',
                          label: 'Tags',
                          sortable: true,
                          width: '80px',
                        },
                        {
                          key: 'channels',
                          label: 'Channels',
                          width: '80px',
                        },
                        {
                          key: 'valueMapping',
                          label: 'Features',
                          width: '80px',
                        },
                        {
                          key: 'createdAt',
                          label: 'Created',
                          sortable: true,
                          width: '80px',
                        },
                        {
                          key: 'name',
                          label: 'Action',
                          width: '80px',
                        },
                      ]}
                      selectable={false}
                    />
                  </InputContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={addStockTemplateModal}
        onClose={() => setAddStockTemplateModal(false)}
      >
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 2 }}
          minWidth="lg"
        >
          <ModalClose />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="h4">Add to Library</Typography>
            </Grid>
            <Grid item xs={12}>
              <FloatLabelInput
                label="Name"
                value={stockTemplateName || ''}
                onChange={setStockTemplateName}
              ></FloatLabelInput>
            </Grid>
            <Grid item xs={12}>
              <FloatLabelTextarea
                label="Description"
                value={stockTemplateDescription || ''}
                minRows={10}
                onChange={setStockTemplateDescription}
              ></FloatLabelTextarea>
            </Grid>
            <Grid item xs={12}>
              <FloatLabelInput
                label="Category"
                value={stockTemplateCategory || ''}
                onChange={setStockTemplateCategory}
              ></FloatLabelInput>
            </Grid>
            <Grid item xs={12}>
              <FloatLabelInput
                label="Sub Category"
                value={stockTemplateSubCategory || ''}
                onChange={setStockTemplateSubCategory}
              ></FloatLabelInput>
            </Grid>
            <Grid item xs={12}>
              <MultipleSelect
                value={stockTemplateTags}
                options={stockTemplateTags}
                freeSolo
                placeholder="Tags"
                autoHighlight={false}
                onChange={(values) => setStockTemplateTags(values)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ ml: 1 }}
                endDecorator={
                  <Checkbox
                    sx={{ ml: 0.5 }}
                    checked={stockTemplateAllChannels}
                    onChange={() =>
                      setStockTemplateAllChannels(!stockTemplateAllChannels)
                    }
                  ></Checkbox>
                }
              >
                <strong>All Channels</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ ml: 1 }}
                endDecorator={
                  <Checkbox
                    sx={{ ml: 0.5 }}
                    checked={stockTemplateValueMapping}
                    onChange={() =>
                      setStockTemplateValueMapping(!stockTemplateValueMapping)
                    }
                  ></Checkbox>
                }
              >
                <strong>Value Mapping</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right' }}
                onClick={() => handleAddStockTemplate()}
                disabled={!stockTemplateName || !stockTemplateCategory}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      />
    </Component>
  )
}
