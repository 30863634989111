import React, { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavBar from '../components/common/NavBar'
import Container from '@mui/joy/Container'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Component from '../components/common/Component'
import Typography from '@mui/joy/Typography'
import { err, formatDate, isDefined } from '../utils/functions'
import {
  getIntegrations,
  GetIntegrationsResult,
  IntegrationColors,
  IntegrationDisplayName,
  IntegrationDisplayNameToName,
} from '../api/integration'
import {
  getOrderAnalytics,
  getOrdersByDay,
  getTemplateAnalytics,
  OrderAnalytics,
  OrdersByDay,
  TemplateAnalytics,
  searchOrdersByProduct,
  SearchOrdersByProduct,
} from '../api/order'
import Alert, { AlertInput } from '../components/common/Alert'

import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts/LineChart'
import MultipleSelect from '../components/common/MultipleSelect'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import InputContainer from '../components/common/InputContainer'
import Button from '@mui/joy/Button'
import Tooltip from '@mui/joy/Tooltip'
import Box from '@mui/joy/Box'

import SingleSelect from '../components/common/SingleSelect'
import { getAllAttributeValues, getAllSkus } from '../api/product'
import Add from '@mui/icons-material/Add'
import { Chip } from '@mui/joy'

export default function AnalyticsPage(): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState<OrderAnalytics[]>([])

  const [productsData, setProductsData] = useState<TemplateAnalytics[]>([])
  const [ordersByDay, setOrdersByDay] = useState<OrdersByDay[]>([])
  const [ordersByProduct, setOrdersByProduct] = useState<
    SearchOrdersByProduct[]
  >([])

  // const [attributeNames]

  const [startDate, setStartDate] = useState<string>(
    dayjs().subtract(30, 'day').toISOString(),
  )
  const [endDate, setEndDate] = useState<string>(dayjs().toISOString())
  const [channels, setChannels] = useState<string[]>([])

  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [attributeValues, setAttributeValues] = useState<
    { name: string; action?: string; values: string[] }[]
  >([
    {
      name: '',
      values: [],
    },
  ])

  const [availableAttributes, setAvailableAttributes] = useState<string[]>([])
  const [availableAttributeValues, setAvailableAttributeValues] = useState<{
    [k: string]: string[]
  }>({})

  // Skus
  const [skus, setSkus] = useState<string[]>([])
  const [availableSkus, setAvailableSkus] = useState<string[]>([])
  // const [availableTags, setAvailableTags] = useState<string[]>([])

  useEffect(() => {
    getIntegrations()
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => err(e))

    getAllSkus()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableSkus(res.data)
        }
      })
      .catch((e) => err(e))
    // Get tags
    // getAllTags()
    //   .then((res) => {
    //     if (res.success && res.data) {
    //       setAvailableTags(res.data)
    //     }
    //   })
    //   .catch((e) => err(e))
    // Get attribute values
    getAllAttributeValues()
      .then((res) => {
        if (res.success && res.data) {
          setAvailableAttributes(Object.keys(res.data))
          setAvailableAttributeValues(res.data)
        }
      })
      .catch((e) => err(e))

    // getOrderAnalytics()
    //   .then((res) => {
    //     if (res.success && res.data) {
    //       setData(res.data)
    //     }
    //   })
    //   .catch((e) => err(e))
    // getTemplateAnalytics()
    //   .then((res) => {
    //     if (res.success && res.data) {
    //       setProductsData(res.data)
    //     }
    //   })
    //   .catch((e) => err(e))

    // getOrdersByDay()
    //   .then((res) => {
    //     if (res.success && res.data) {
    //       setOrdersByDay(res.data)
    //     }
    //   })
    //   .catch((e) => err(e))
  }, [])

  useEffect(() => {
    const channelNames = channels
      .map((v) => IntegrationDisplayNameToName[v])
      .filter(isDefined)
    const query = {
      startDate,
      endDate,
      channels: channelNames,
      skus,
    }

    getOrderAnalytics(query)
      .then((res) => {
        if (res.success && res.data) {
          setData(res.data)
        }
      })
      .catch((e) => err(e))

    getTemplateAnalytics(query)
      .then((res) => {
        if (res.success && res.data) {
          setProductsData(res.data)
        }
      })
      .catch((e) => err(e))

    getOrdersByDay(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrdersByDay(res.data)
        }
      })
      .catch((e) => err(e))

    searchOrdersByProduct(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrdersByProduct(res.data)
        }
      })
      .catch((e) => err(e))
  }, [startDate, endDate, channels, skus])

  const handleAttributeChange = (
    index: number,
    name: string | undefined,
    values?: string[],
  ) => {
    if (index < 0) return
    const newAttributeValues = attributeValues.slice()

    const existingAttributeValue = attributeValues[index]
    const newAttributeValue = {
      name: name || '',
      values: values || [],
      action: existingAttributeValue?.action || 'in',
    }
    newAttributeValues[index] = newAttributeValue

    setAttributeValues(newAttributeValues)
  }

  const handleAddAttribute = () => {
    const newAttributeValues = attributeValues.concat({
      name: '',
      values: [],
    })
    setAttributeValues(newAttributeValues)
  }

  const handleByProductSearch = () => {
    const query = {
      startDate,
      endDate,
      attributes: attributeValues,
      skus: skus,
    }
    searchOrdersByProduct(query)
      .then((res) => {
        if (res.success && res.data) {
          setOrdersByProduct(res.data)
        }
      })
      .catch((e) => err(e))
  }

  const NoData = (
    <Box py={'150px'}>
      <Typography level="title-md">
        <strong>No Data</strong>
      </Typography>
    </Box>
  )

  return (
    <div
      id="bg"
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'} sx={{ marginTop: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Component title={'Analytics'}>
              <Grid item xs={12} mt={1}>
                <Grid container justifyContent="center" spacing={1} px={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="soft"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(365, 'day').toISOString(),
                            )
                          }}
                        >
                          1 Year
                        </Chip>
                      </Grid>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(180, 'day').toISOString(),
                            )
                          }}
                        >
                          6 Months
                        </Chip>
                      </Grid>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(90, 'day').toISOString(),
                            )
                          }}
                        >
                          3 Months
                        </Chip>
                      </Grid>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(30, 'day').toISOString(),
                            )
                          }}
                        >
                          1 Month
                        </Chip>
                      </Grid>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(7, 'day').toISOString(),
                            )
                          }}
                        >
                          1 Week
                        </Chip>
                      </Grid>
                      <Grid item>
                        <Chip
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setEndDate(dayjs().toISOString())
                            setStartDate(
                              dayjs().subtract(1, 'day').toISOString(),
                            )
                          }}
                        >
                          1 Day
                        </Chip>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <DatePicker
                      value={
                        startDate
                          ? dayjs(new Date(startDate))
                          : dayjs().subtract(14, 'day')
                      }
                      onChange={(v) => setStartDate(v?.toISOString() || '')}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'outlined',
                          label: 'Start Date',
                          size: 'small',
                          sx: {
                            backgroundColor: '#FBFCFE',
                            color: '#32383E',
                            padding: '0px 0px',
                            lineHeight: '30px',
                            fontSize: 'medium',
                            border: '1px',
                            borderStyle: 'solid',
                            borderColor:
                              'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                            borderRadius: '6px',
                            boxShadow:
                              '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                            textAlign: 'left',
                            maxHeight: '600px',
                            overflow: 'visible',
                            '& .MuiOutlinedInput-root': {
                              height: '34px',
                              '& fieldset': {
                                border: 'none',
                                padding: '0px',
                              },
                            },
                            '& label': {
                              lineHeight: '18px',
                              background:
                                'linear-gradient(rgb(251, 252, 254), rgb(251, 252, 254)) 0px 100% / 100% 0.77rem no-repeat',
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <DatePicker
                      value={endDate ? dayjs(new Date(endDate)) : dayjs()}
                      onChange={(v) => setEndDate(v?.toISOString() || '')}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'outlined',
                          label: 'End Date',
                          size: 'small',
                          sx: {
                            backgroundColor: '#FBFCFE',
                            color: '#32383E',
                            padding: '0px 0px',
                            lineHeight: '34px',
                            fontSize: 'medium',
                            border: '1px',
                            borderStyle: 'solid',
                            borderColor:
                              'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
                            borderRadius: '6px',
                            boxShadow:
                              '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08)',
                            textAlign: 'left',
                            maxHeight: '600px',
                            overflow: 'visible',
                            '& .MuiOutlinedInput-root': {
                              height: '34px',
                              '& fieldset': {
                                border: 'none',
                              },
                            },
                            '& label': {
                              lineHeight: '18px',
                              background:
                                'linear-gradient(rgb(251, 252, 254), rgb(251, 252, 254)) 0px 100% / 100% 0.77rem no-repeat',
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MultipleSelect
                      size="md"
                      floatLabel="Channels"
                      value={channels}
                      onChange={setChannels}
                      options={Object.keys(IntegrationDisplayNameToName)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MultipleSelect
                      size="md"
                      floatLabel="SKUs"
                      value={skus}
                      onChange={setSkus}
                      options={availableSkus}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12}>
            <Component>
              <Grid
                container
                spacing={1}
                sx={{
                  px: md ? 2 : 0,
                }}
              >
                <Grid item xs={12} mt={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                          <InputContainer>
                            <Box p={2}>
                              <Typography level="title-lg">
                                <strong>Orders</strong>
                              </Typography>
                              <Typography level="body-lg">
                                {data?.[0]?.count || 0}
                              </Typography>
                            </Box>
                          </InputContainer>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <InputContainer>
                            <Box py={2}>
                              <Typography level="title-lg">
                                <strong>Total Sales</strong>
                              </Typography>
                              <Typography level="body-lg">
                                ${(data?.[0]?.totalQuantity || 0).toFixed(2)}
                              </Typography>
                            </Box>
                          </InputContainer>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <InputContainer>
                            <Box py={2}>
                              <Typography level="title-lg">
                                <strong>Profit</strong>
                              </Typography>
                              <Tooltip
                                title="Sales - Fees, Tax, etc."
                                size="sm"
                              >
                                <Typography level="body-lg">
                                  ${(data?.[0]?.netAmount || 0).toFixed(2)}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </InputContainer>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <InputContainer>
                            <Box py={2}>
                              <Typography level="title-lg">
                                <strong>Items Sold</strong>
                              </Typography>
                              <Typography level="body-lg">
                                {data?.[0]?.totalQuantity || 0}
                              </Typography>
                            </Box>
                          </InputContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <InputContainer>
                        <Box pb={3} px={1}>
                          <Typography level="title-lg" mb={3}>
                            <strong>Daily Orders</strong>
                          </Typography>
                          {ordersByDay.length ? (
                            <LineChart
                              slotProps={{
                                legend: {
                                  itemMarkWidth: 12,
                                  itemMarkHeight: 12,
                                  labelStyle: {
                                    fontSize: 12,
                                    fillOpacity: 0.8,
                                  },
                                },
                              }}
                              yAxis={[
                                {
                                  label: '# of Orders',
                                  min: 0,
                                },
                              ]}
                              xAxis={[
                                {
                                  id: 'Channel Sales',
                                  data: ordersByDay.map((d) =>
                                    formatDate(d.day),
                                  ),
                                  scaleType: 'band',
                                  // categoryGapRatio: 0.4,
                                  // barGapRatio: 0.8,
                                },
                              ]}
                              // series={[
                              //   {
                              //     data: ordersByDay.map((g) => g.count || 0),
                              //     color:
                              //       'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                              //   },
                              // ]}
                              series={[
                                'ebay',
                                'etsy',
                                'square',
                                'shopify',
                                'bigcommerce',
                              ].map((key) => ({
                                curve: 'linear',
                                id: key,
                                dataKey: key,
                                label: IntegrationDisplayName[key],
                                showMark: false,
                                stack: 'total',
                                area: true,
                                color: IntegrationColors[key],
                                stackOffset: 'none', // To stack 0 on top of others
                              }))}
                              dataset={ordersByDay}
                              margin={{
                                top: 25,
                                bottom: 25,
                                left: 50,
                                right: 50,
                              }}
                              height={400}
                              sx={{
                                '& .MuiAreaElement-root': {
                                  // fill: 'rgba(0, 207, 180, 1)',
                                  // fill: 'url(#ebayGradient)',

                                  // stroke: '#02ad97',
                                  fillOpacity: '0.5',
                                  strokeOpacity: '1',
                                },
                                '& .MuiAreaElement-series-ebay': {
                                  fill: 'url(#ebayGradient)',
                                  fillOpacity: '0.6',
                                },
                                '& .MuiAreaElement-series-etsy': {
                                  fill: 'url(#etsyGradient)',
                                  fillOpacity: '0.6',
                                },
                                '& .MuiAreaElement-series-square': {
                                  fill: 'url(#squareGradient)',
                                  fillOpacity: '0.6',
                                },
                                '& .MuiAreaElement-series-shopify': {
                                  fill: 'url(#shopifyGradient)',
                                  fillOpacity: '0.6',
                                },
                                '& .MuiAreaElement-series-bigcommerce': {
                                  fill: 'url(#bigcommerceGradient)',
                                  fillOpacity: '0.6',
                                },
                              }}
                            >
                              <linearGradient
                                id="ebayGradient"
                                y1="100%"
                                x2="0%"
                                y2="0%"
                              >
                                <stop offset="0%" stopColor="#fff" />
                                <stop
                                  offset="100%"
                                  stopColor={IntegrationColors['ebay']}
                                />
                              </linearGradient>
                              <linearGradient
                                id="etsyGradient"
                                y1="100%"
                                x2="0%"
                                y2="0%"
                              >
                                <stop offset="0%" stopColor="#fff" />
                                <stop
                                  offset="100%"
                                  stopColor={IntegrationColors['etsy']}
                                />
                              </linearGradient>
                              <linearGradient
                                id="squareGradient"
                                y1="100%"
                                x2="0%"
                                y2="0%"
                              >
                                <stop offset="0%" stopColor="#fff" />
                                <stop
                                  offset="100%"
                                  stopColor={IntegrationColors['square']}
                                />
                              </linearGradient>
                              <linearGradient
                                id="shopifyGradient"
                                y1="100%"
                                x2="0%"
                                y2="0%"
                              >
                                <stop offset="0%" stopColor="#fff" />
                                <stop
                                  offset="100%"
                                  stopColor={IntegrationColors['shopify']}
                                />
                              </linearGradient>
                              <linearGradient
                                id="bigcommerceGradient"
                                y1="100%"
                                x2="0%"
                                y2="0%"
                              >
                                <stop offset="0%" stopColor="#fff" />
                                <stop
                                  offset="100%"
                                  stopColor={IntegrationColors['bigcommerce']}
                                />
                              </linearGradient>
                            </LineChart>
                          ) : (
                            NoData
                          )}
                        </Box>
                      </InputContainer>
                    </Grid>

                    {/* <Grid item xs={12} lg={6}>
                      <InputContainer>
                        <Box pb={3} px={1}>
                          <Typography level="title-lg" mb={3}>
                            <strong>Channels</strong>
                          </Typography>
                          {data.length ? (
                            <PieChart
                              skipAnimation={false}
                              // slotProps={{
                              //   popper: {
                              //     sx: {
                              //       '--my-custom-gradient':
                              //         'linear-gradient(0deg, #123456, #81b2e4);',
                              //     },
                              //   },
                              // }}
                              xAxis={[
                                {
                                  id: 'Channel Sales',
                                  data: data.map(
                                    (g) => IntegrationDisplayName[g.channel],
                                  ),
                                  scaleType: 'band',
                                  // categoryGapRatio: 0.4,
                                  // barGapRatio: 0.8,
                                },
                              ]}
                              series={[
                                {
                                  data: data.map((g, i) => ({
                                    id: i,
                                    value: g.count,
                                    label: IntegrationDisplayName[g.channel],
                                    color: 'url(#gradientColor)', //IntegrationColors[g.channel],
                                  })),
                                  innerRadius: 30,
                                  outerRadius: 125,
                                  paddingAngle: 2,
                                  cornerRadius: 5,
                                },
                              ]}
                              margin={{
                                left: 50,
                                right: 50,
                              }}
                              colors={['#02ad97']}
                              height={300}
                              sx={{
                                '& .MuiPieChart-sector': {
                                  stroke: '#ffffff', // To separate the segments
                                  strokeWidth: 2,
                                  fillOpacity: 0.5,
                                },
                                '& .MuiPieChart-root': {
                                  // fill: '#02ad97',
                                },
                              }}
                            >
                              <svg width={0} height={0}>
                                <defs>
                                  <linearGradient
                                    id="gradientColor"
                                    x1="0%"
                                    y1="0%"
                                    x2="100%"
                                    y2="100%"
                                  >
                                    <stop offset="0%" stopColor="#fff" />
                                    <stop
                                      offset="25%"
                                      stopColor="rgba(0, 207, 180, 0.6)"
                                    />
                                    <stop
                                      offset="100%"
                                      stopColor="rgba(0, 207, 180, 0.9)"
                                    />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </PieChart>
                          ) : null}
                        </Box>
                      </InputContainer>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Component>
              <InputContainer>
                <Box pb={3} px={1}>
                  <Typography level="title-lg" mb={3}>
                    <strong>Sales By Template</strong>
                  </Typography>
                  {productsData.length ? (
                    <BarChart
                      skipAnimation={false}
                      yAxis={[{ label: '# Sold' }]}
                      xAxis={[
                        {
                          id: 'Template Sales',
                          data: productsData.map((g) => g.templateName),
                          scaleType: 'band',
                        },
                      ]}
                      series={[
                        {
                          data: productsData.map(
                            (g) => parseInt(`${g.quantity}`) || 0,
                          ),
                          color: 'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                        },
                      ]}
                      margin={{
                        top: 25,
                        bottom: 25,
                        left: 50,
                        right: 50,
                      }}
                      colors={['#02ad97']}
                      height={300}
                      sx={{
                        '--my-custom-gradient': 'url(#GlobalGradient)',
                        '.MuiBarElement-root': {
                          ry: '3',
                          rx: '3',
                          // stroke: '#02ad97',
                          // fill: '#02ad97',
                          // fillOpacity: '0.6',
                          // strokeOpacity: '0.9',
                        },
                      }}
                    >
                      <linearGradient
                        id="GlobalGradient"
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                      >
                        <stop offset="0%" stopColor="rgba(0, 207, 180, 0.5)" />
                        <stop offset="2%" stopColor="rgba(0, 207, 180, 0.3)" />
                        <stop
                          offset="99%"
                          stopColor="rgba(0, 207, 180, 0.75)"
                        />
                        <stop
                          offset="100%"
                          stopColor="rgba(0, 207, 180, 0.1)"
                        />
                      </linearGradient>
                    </BarChart>
                  ) : (
                    NoData
                  )}
                </Box>
              </InputContainer>
            </Component>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Component>
              <InputContainer>
                <Box pb={3} px={1}>
                  <Typography level="title-lg" mb={3}>
                    <strong>Sales By Channel</strong>
                  </Typography>
                  {data.length ? (
                    <BarChart
                      skipAnimation={false}
                      // slotProps={{
                      //   popper: {
                      //     sx: {
                      //       '--my-custom-gradient':
                      //         'linear-gradient(0deg, #123456, #81b2e4);',
                      //     },
                      //   },
                      // }}
                      yAxis={[{ label: '# of Sales' }]}
                      xAxis={[
                        {
                          id: 'Channel Sales',
                          data: data.map(
                            (g) => IntegrationDisplayName[g.channel],
                          ),
                          scaleType: 'band',
                          // categoryGapRatio: 0.4,
                          // barGapRatio: 0.8,
                        },
                      ]}
                      series={[
                        {
                          data: data.map((g) => parseInt(`${g.count}`) || 0),
                          color: 'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                        },
                      ]}
                      margin={{
                        top: 25,
                        bottom: 25,
                        left: 50,
                        right: 50,
                      }}
                      colors={['#02ad97']}
                      height={300}
                      sx={{
                        '--my-custom-gradient': 'url(#GlobalGradient)',
                        '.MuiBarElement-root': {
                          ry: '5',
                          rx: '5',
                          // stroke: '#02ad97',
                          // fill: '#02ad97',
                          // fillOpacity: '0.6',
                          // strokeOpacity: '0.9',
                        },
                      }}
                    >
                      <linearGradient
                        id="GlobalGradient"
                        x1="0%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                      >
                        <stop offset="0%" stopColor="rgba(0, 207, 180, 0.5)" />
                        <stop offset="2%" stopColor="rgba(0, 207, 180, 0.3)" />
                        <stop
                          offset="99%"
                          stopColor="rgba(0, 207, 180, 0.75)"
                        />
                        <stop
                          offset="100%"
                          stopColor="rgba(0, 207, 180, 0.1)"
                        />
                      </linearGradient>
                    </BarChart>
                  ) : (
                    NoData
                  )}
                </Box>
              </InputContainer>
            </Component>
          </Grid>

          <Grid item xs={12}>
            <Component>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography level="h4">
                        <strong>Product Insights</strong>
                      </Typography>
                    </Grid>

                    {attributeValues.map((attribute, index) => {
                      return (
                        <Grid item xs={12} key={attribute.name + index}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <SingleSelect
                                floatLabel="Attribute"
                                options={availableAttributes}
                                value={attribute.name}
                                onChange={(value) =>
                                  handleAttributeChange(index, value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MultipleSelect
                                freeSolo={true}
                                floatLabel="Attribute Values"
                                options={
                                  availableAttributeValues[attribute.name]
                                }
                                value={attribute.values}
                                onChange={(values) =>
                                  handleAttributeChange(
                                    index,
                                    attribute.name,
                                    values,
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                    <Grid item xs={12} sx={{ marginTop: '-4px' }}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        {/* <Grid item>
                              <Typography
                                level="title-sm"
                                component="label"
                                endDecorator={
                                  <Switch
                                    checked={matchAllAttributes}
                                    onChange={() =>
                                      setMatchAllAttributes(!matchAllAttributes)
                                    }
                                  />
                                }
                              >
                                <strong>Match All</strong>
                              </Typography>
                            </Grid> */}
                        <Grid item>
                          <Button
                            onClick={handleAddAttribute}
                            variant="soft"
                            size="sm"
                            color="neutral"
                            endDecorator={<Add fontSize="small" />}
                          >
                            Add Attribute
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{ float: 'right' }}
                        onClick={handleByProductSearch}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <InputContainer>
                    <Box pb={3} px={1}>
                      <Typography level="title-lg" mb={3}>
                        <strong>Sales By Product</strong>
                      </Typography>
                      {ordersByProduct.length ? (
                        <BarChart
                          skipAnimation={false}
                          // slotProps={{
                          //   popper: {
                          //     sx: {
                          //       '--my-custom-gradient':
                          //         'linear-gradient(0deg, #123456, #81b2e4);',
                          //     },
                          //   },
                          // }}
                          yAxis={[{ label: '# Sold' }]}
                          xAxis={[
                            {
                              id: 'Channel Sales',
                              data: ordersByProduct.map((d) => d.sku),
                              scaleType: 'band',
                              // categoryGapRatio: 0.4,
                              // barGapRatio: 0.8,
                            },
                          ]}
                          series={[
                            {
                              data: ordersByProduct.map((d) => d.quantity),
                              color:
                                'var(--my-custom-gradient, #b0f7ee, #02ad97)',
                            },
                          ]}
                          margin={{
                            top: 25,
                            bottom: 25,
                            left: 50,
                            right: 50,
                          }}
                          colors={['#02ad97']}
                          height={300}
                          sx={{
                            '--my-custom-gradient': 'url(#GlobalGradient)',
                            '.MuiBarElement-root': {
                              ry: '3',
                              rx: '3',
                              // stroke: '#02ad97',
                              // fill: '#02ad97',
                              // fillOpacity: '0.6',
                              // strokeOpacity: '0.9',
                            },
                          }}
                        >
                          <linearGradient
                            id="GlobalGradient"
                            x1="0%"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                          >
                            <stop
                              offset="0%"
                              stopColor="rgba(0, 207, 180, 0.5)"
                            />
                            <stop
                              offset="2%"
                              stopColor="rgba(0, 207, 180, 0.3)"
                            />
                            <stop
                              offset="99%"
                              stopColor="rgba(0, 207, 180, 0.75)"
                            />
                            <stop
                              offset="100%"
                              stopColor="rgba(0, 207, 180, 0.1)"
                            />
                          </linearGradient>
                        </BarChart>
                      ) : (
                        NoData
                      )}
                    </Box>
                  </InputContainer>
                </Grid>
              </Grid>
            </Component>
          </Grid>

          <Grid item xs={12} mt={40}></Grid>
        </Grid>
      </Container>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
