import React, { useEffect, useState } from 'react'
import ChannelIntegrationsComponent from '../common/ChannelIntegrationsComponent'
import {
  deleteEbayTemplateIntegration,
  setEbayProductIntegrations,
  setEbayTemplateIntegrations,
} from '../../../../api/integrations/ebay'
import {
  getChannelIntegration,
  GetIntegrationResult,
  IntegrationName,
} from '../../../../api/integration'
import {
  EbayProductIntegration,
  EbayTemplateAspectIntegration,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import EbayIntegrationComponent from './EbayIntegrationConfigurationComponent'
import { err } from '../../../../utils/functions'
import { Box, CircularProgress } from '@mui/joy'
import {
  getEbayCategories,
  GetProduct,
  GetProductTemplate,
} from '../../../../api/product'
import LoadingBox from '../../../common/LoadingBox'
import MissingIntegration from '../MissingIntegration'
import UnauthenticatedIntegration from '../UnauthenticatedIntegration'
import {
  CategoryTree,
  FulfillmentPolicy,
  InventoryLocation,
  PaymentPolicy,
  ReturnPolicy,
} from '../../../../types/Ebay.types'
import {
  getEbayInventoryLocations,
  getEbayListingPolicies,
} from '../../../../api/integrations/ebay'
import { DataFields } from '../../../../utils/types'

export type AspectIntegrationInput = Omit<
  DataFields<EbayTemplateAspectIntegration>,
  'ebayTemplateIntegrationId' | 'type'
>

export default function EbayIntegrationsComponent({
  integration: defaultIntegration,
  template,
  product,
  onUpdate,
}: {
  integration?: GetIntegrationResult<IntegrationName.EBAY> | undefined
  template: GetProductTemplate
  product?: GetProduct
  onUpdate?: () => void
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [integration, setIntegration] = useState<
    GetIntegrationResult<IntegrationName.EBAY> | undefined
  >()

  const [categories, setCategories] = useState<CategoryTree | undefined>()
  const [locations, setLocations] = useState<InventoryLocation[] | undefined>()
  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicy[] | undefined
  >()
  const [returnPolicies, setReturnPolicies] = useState<
    ReturnPolicy[] | undefined
  >()
  const [fulfillmentPolicies, setFulfillmentPolicies] = useState<
    FulfillmentPolicy[] | undefined
  >([])

  useEffect(() => {
    if (!integration) {
      getChannelIntegration(
        IntegrationName.EBAY,
        template.template.id,
        product?.product.id,
      )
        .then((res) => {
          setLoading(false)
          if (res.success && res.data) {
            setIntegration(res.data)
          }
        })
        .catch((e) => {
          setLoading(false)
          err(e)
        })
    }

    getEbayCategories()
      .then((res) => {
        setLoading(false)
        if (res.success && res.data) {
          setCategories(res.data)
        }
      })
      .catch((e) => {
        err(e)
      })

    getEbayListingPolicies()
      .then((res) => {
        if (res.success && res.data) {
          setPaymentPolicies(res.data.paymentPolicies || [])
          setReturnPolicies(res.data.returnPolicies || [])
          setFulfillmentPolicies(res.data.fulfillmentPolicies || [])
        }
      })
      .catch((e) => {
        setPaymentPolicies([])
        setReturnPolicies([])
        setFulfillmentPolicies([])
        err(e)
      })

    getEbayInventoryLocations()
      .then((res) => {
        if (res.success && res.data) {
          setLocations(res.data || [])
        }
      })
      .catch((e) => {
        setLocations([])
        err(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    )
  }

  if (!integration) {
    return (
      <Box p={4}>
        <MissingIntegration />
      </Box>
    )
  }

  if (integration && !integration.authenticated) {
    return (
      <Box p={4}>
        <UnauthenticatedIntegration channelName={IntegrationName.EBAY} />
      </Box>
    )
  }

  return (
    <>
      <ChannelIntegrationsComponent
        channel={IntegrationName.EBAY}
        template={template}
        product={product}
        integration={integration}
        onUpdate={onUpdate}
        onTemplateDelete={async (id) => {
          console.log('Delete', id)
          return await deleteEbayTemplateIntegration(id)
        }}
        onTemplateSave={setEbayTemplateIntegrations}
        onProductSave={setEbayProductIntegrations}
        Component={({
          templateIntegration,
          productIntegration,
          template,
          product,
          count,
          onTemplateChange,
          onProductChange,
          onUpdate,
        }) =>
          !categories ||
          !locations ||
          !paymentPolicies ||
          !returnPolicies ||
          !fulfillmentPolicies ? (
            <LoadingBox />
          ) : (
            <EbayIntegrationComponent
              // integration={integration}
              templateIntegration={templateIntegration}
              count={count}
              template={template}
              product={product}
              productIntegration={productIntegration}
              categories={categories}
              locations={locations}
              paymentPolicies={paymentPolicies}
              returnPolicies={returnPolicies}
              fulfillmentPolicies={fulfillmentPolicies}
              onTemplateChange={(name, templateIntegration) =>
                onTemplateChange?.(
                  name,
                  templateIntegration as EbayTemplateIntegration,
                )
              }
              onProductChange={(name, productIntegration) =>
                onProductChange?.(
                  name,
                  productIntegration as EbayProductIntegration,
                )
              }
              onUpdate={onUpdate}
            />
          )
        }
      />
    </>
  )
}
