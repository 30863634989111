import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import {
  Aspect,
  AspectModeEnum,
  EbayTemplateIntegration,
} from '../../../../types/Ebay.types'
import {
  getAttributeName,
  getAttributeTag,
  isLike,
} from '../../../../utils/functions'
import {
  GetProductTemplateAttribute,
  ProductTemplateAttributeInput,
  updateProductTemplateAttributes,
} from '../../../../api/product'

import { useMemo } from 'react'
import { DEFAULT_ATTRIBUTE_TYPE } from '../../../../utils/constants'
import AspectExamples from './AspectExamples'
import { AspectIntegrationInput } from './EbayIntegrationsComponent'
import AttributeSelect from '../../../common/AttributeSelect'
import { IntegrationName } from '../../../../api/integration'

export default function AspectGroup({
  aspectIntegration,
  aspect,
  templateAttributes,
  templateIntegration,
  valueMapping,
  onValueMapChange,
  channelOptions,
  onChange,
  onUpdate,
}: {
  aspect: Aspect
  aspectIntegration: AspectIntegrationInput | undefined
  templateIntegration: EbayTemplateIntegration
  templateAttributes: GetProductTemplateAttribute[]
  valueMapping?: Record<string, Record<string, string[]>>
  onValueMapChange?: (
    aspectName: string,
    value: string,
    values: string[],
  ) => void
  channelOptions?: Record<
    string,
    Record<string, string[] | undefined> | undefined
  >
  onChange: (aspect: AspectIntegrationInput) => void
  onUpdate?: () => void
}): JSX.Element {
  const attributeMatch = templateAttributes.find((a) =>
    isLike(a.attribute.name, aspect.localizedAspectName),
  )

  const handleChange = (newValue: string) => {
    const newAspect: AspectIntegrationInput = {
      value: newValue,
      aspectName: aspect.localizedAspectName,
      dataType: aspect.aspectConstraint.aspectDataType,
      required: aspect.aspectConstraint.aspectRequired,
    }
    onChange(newAspect)
  }

  const templateAttributeSelected = getAttributeName(aspectIntegration?.value)

  const selectedTemplateAttribute = templateAttributeSelected
    ? templateAttributes.find((a) =>
        isLike(a.attribute.name, templateAttributeSelected),
      )
    : aspectIntegration === undefined
    ? templateAttributes.find((a) =>
        isLike(a.attribute.name, aspect.localizedAspectName),
      )
    : undefined

  const addToTemplate = !templateAttributes.find((a) =>
    isLike(a.attribute.name, aspect.localizedAspectName),
  )

  const freeSolo =
    aspect.aspectConstraint.aspectMode === AspectModeEnum.FREE_TEXT

  const aspectLabel = freeSolo ? 'Example Values' : 'Allowed Values'

  const options = useMemo(() => {
    return aspect.aspectValues?.map((value) => value.localizedValue) || []
  }, [aspect.aspectValues])

  const handleAddTemplateAttributeOption = async (options: string[]) => {
    if (!selectedTemplateAttribute) return
    // Get template id from template attribute
    const templateId = selectedTemplateAttribute.attribute.templateId
    const templateAttributeName = selectedTemplateAttribute.attribute.name

    const newIndex = templateAttributes.length // length+1
    const newOptionIndex = selectedTemplateAttribute.attributeOptions.length // length+1
    if (!templateId || !templateAttributeName) return

    // add attribute with options
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: templateAttributeName,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: selectedTemplateAttribute.attribute.index || newIndex,
      },
      attributeOptions: options.map((o) => ({
        value: o,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newOptionIndex,
      })),
    }

    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log('success')
    }
    onUpdate?.()
  }

  const handleAddTemplateAttribute = async (option: string) => {
    // Get template id from first template attribute
    const templateId = templateAttributes?.[0]?.attribute.templateId
    const newIndex = templateAttributes.length // length+1
    if (!templateId) return
    // add attribute
    const addTemplateAttr: ProductTemplateAttributeInput = {
      attribute: {
        name: option,
        type: DEFAULT_ATTRIBUTE_TYPE,
        index: newIndex,
      },
      attributeOptions: [],
    }
    const res = await updateProductTemplateAttributes(templateId, [
      addTemplateAttr,
    ])
    if (res.success && res.data) {
      console.log('success')
    }
    handleChange?.(getAttributeTag(option))
    onUpdate?.()
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Grid container alignContent="center" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Typography
              sx={{
                border: '1px solid #e8e9eb',
                lineHeight: '34px',
                px: '8px',
                borderRadius: '6px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
                boxShadow: '0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);',
                color: '#32383E',
                backgroundColor: '#FBFCFE',
              }}
            >
              <strong title={`${aspect.localizedAspectName}`}>
                {aspect.localizedAspectName}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={10}>
            <Grid container spacing={1} alignContent="center">
              <Grid
                item
                sm={options.length ? 7 : 12}
                lg={options.length ? 8 : 12}
                xs={12}
              >
                <AttributeSelect
                  value={
                    aspectIntegration?.value ??
                    getAttributeTag(attributeMatch?.attribute.name || '')
                  }
                  attributeNames={templateAttributes.map(
                    (a) => a.attribute.name,
                  )}
                  channel={IntegrationName.EBAY}
                  templateIntegration={templateIntegration}
                  attributeName={aspect.localizedAspectName}
                  onChange={(newValue) => handleChange(newValue || '')}
                  onUpdate={onUpdate}
                  channelAttributeOptions={channelOptions}
                  options={aspect.aspectValues?.map((a) => a.localizedValue)}
                  addOptions={addToTemplate ? [aspect.localizedAspectName] : []}
                  onAddOptionClick={handleAddTemplateAttribute}
                  valueMapping={valueMapping?.[aspect.localizedAspectName]}
                  onValueMappingChange={(value, values) =>
                    onValueMapChange?.(
                      aspect.localizedAspectName,
                      value,
                      values,
                    )
                  }
                  allowedValues={aspect.aspectValues?.map(
                    (a) => a.localizedValue,
                  )}
                />
              </Grid>
              {options.length ? (
                <Grid item sm={5} xs={12} lg={4}>
                  <AspectExamples
                    label={aspectLabel}
                    options={options}
                    templateAttribute={selectedTemplateAttribute}
                    onChange={handleAddTemplateAttributeOption}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
